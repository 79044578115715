import axios from "axios"
import moment from "moment"
import React from "react"
import { Link, navigate } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import queries from "../../queries/bookingform"

import "../../styles/details.css"

class PersonalDetails extends React.Component {
  GRAPHQL_API = process.env.GATSBY_PIH_URL

  state = {
    Title: "",
    Initials: "",
    Surname: "",
    Contact: "",
    AreaCode: "",
    PhoneNumber: "",
    Email: "",
    Email2: "",

    passengerExists: false,
    editMode: true,
    passengers: [],
  }

  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.editPassenger = this.editPassenger.bind(this)
  }

  componentDidMount() {
    const token =
      typeof window !== "undefined" && window.localStorage.getItem("token")
    const bookingRef =
      typeof window !== "undefined" && window.localStorage.getItem("bookingRef")
    const expiresAt =
      typeof window !== "undefined" && window.localStorage.getItem("expiresAt")
    const paymentSessionID =
      typeof window !== "undefined" &&
      window.localStorage.getItem("paymentSessionID")

    if (!bookingRef) {
      navigate("/cart/")
      return
    }

    if (moment(expiresAt) <= moment()) {
      typeof window !== "undefined" && window.localStorage.clear()
      this.setState({
        Title: "",
        Initials: "",
        Surname: "",
        Contact: "",
        AreaCode: "",
        PhoneNumber: "",
        Email: "",
        Email2: "",

        passengerExists: false,
        editMode: true,
        passengers: [],
      })

      console.log("Cleared cart due to expiry")

      navigate("/cart/")
      return
    }

    axios
      .post(
        this.GRAPHQL_API,
        {
          query: queries.getPassengerDetails(bookingRef),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => {
        // TODO: Check response.data.errors
        if (response.data.data && response.data.data.getPassengers) {
          this.setState({ passengerExists: true })
          this.setState({ editMode: false })
          this.setState({ passengers: response.data.data.getPassengers })
        }
      })
  }

  changeTitle(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  async submitForm() {
    const token =
      typeof window !== "undefined" && window.localStorage.getItem("token")
    const bookingRef =
      typeof window !== "undefined" && window.localStorage.getItem("bookingRef")
    const paymentSessionID =
      typeof window !== "undefined" &&
      window.localStorage.getItem("paymentSessionID")
    const form = document.getElementById("Form_Customer")

    if (!form.checkValidity()) {
      form.reportValidity()
      return
    }

    let query = null

    query = queries.addPassengerDetails(
      bookingRef,
      this.state.Initials,
      this.state.Surname,
      this.state.Email,
      // XXX this.state.Contact ??
      this.state.PhoneNumber
    )

    if (this.state.passengerExists) {
      query = queries.modifyPassengerDetails(
        bookingRef,
        this.state.passengers[0]["id"],
        this.state.Initials,
        this.state.Surname,
        this.state.Email,
        this.state.PhoneNumber
      )
    }

    const passengerResult = await axios
      .post(
        this.GRAPHQL_API,
        { query },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(resp => resp)

    if (passengerResult.status !== 200 || passengerResult.data.errors) {
      // TODO: Display passengerResult.data.errors somehow, maybe redirect on expired
      console.log("passengerResult error", passengerResult.data.errors)
      return
    }

    // XXX double check that cart isn't in BOOKING before sending ^^

    navigate("/cart/checkout")
  }

  editPassenger() {
    this.setState({ editMode: true })
    console.log(this.state)
  }

  render() {
    return (
      <>
        <SEO title="Passenger Name and Contact Details - awesomeNZ" />
        <Layout
          layoutType="typography TourPage"
          slideshow={<></>}
          hasSliderHandles={false}
          hasSliderBlurSmall={true}
          content={
            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Passenger Details</h1>
                <h3>Tell us a little about yourself</h3>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <div>
                      {this.state?.editMode ? (
                        <form
                          id="Form_Customer"
                          onChange={event => this.changeTitle(event)}
                        >
                          <p
                            id="Form_Customer_error"
                            className="message "
                            style={{ display: "none" }}
                          />
                          <fieldset>
                            <div
                              id="Title"
                              className="field h5dropdown fieldSmall first"
                            >
                              <label
                                className="left"
                                htmlFor="Form_Customer_Title"
                              >
                                Title
                              </label>
                              <div className="middleColumn">
                                <select
                                  className="h5dropdown fieldSmall first"
                                  id="Form_Customer_Title"
                                  name="Title"
                                  required="required"
                                >
                                  <option value />
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Mrs">Mrs</option>
                                  <option value="Miss">Miss</option>
                                  <option value="Master">Master</option>
                                  <option value="Mx">Mx</option>
                                  <option value="M.">M.</option>
                                  <option value="Dr">Dr</option>
                                  <option value="Prof">Prof</option>
                                  <option value="Hon">Hon</option>
                                </select>
                              </div>
                            </div>
                            <div id="Initials" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_Initials"
                              >
                                First Name
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="text"
                                  className="text Initials fieldLarge"
                                  id="Form_Customer_Initials"
                                  name="Initials"
                                  defaultValue=""
                                  autoComplete="on"
                                  required="required"
                                  title="Your first name, or just initials if you prefer."
                                />
                              </div>
                            </div>
                            <div id="Surname" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_Surname"
                              >
                                Surname
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="text"
                                  className="text Surname fieldLarge"
                                  id="Form_Customer_Surname"
                                  name="Surname"
                                  defaultValue=""
                                  autoComplete="on"
                                  required="required"
                                />
                              </div>
                            </div>
                            <div id="Contact" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_Contact"
                              >
                                Alternate Contact
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="text"
                                  className="text Contact fieldLarge"
                                  id="Form_Customer_Contact"
                                  name="Contact"
                                  defaultValue=""
                                  autoComplete="on"
                                  placeholder="Contact Name, if not the passenger."
                                  title="Please supply the name of the person we should contact regarding this booking."
                                />
                              </div>
                            </div>
                            {/*<div id="AreaCode" className="field  first">
                <label className="left" htmlFor="Form_Customer_AreaCode">
                  Phone Number
                </label>
                <div className="middleColumn">
                  <input
                    type="tel"
                    className="text fieldSmall"
                    id="Form_Customer_AreaCode"
                    name="AreaCode"
                    defaultValue=""
                    autoComplete="on"
                    required="required"
                    placeholder="Prefix"
                    title="Please supply a NZ number (Area Code / Mobile Prefix) we can contact you on during the period of travel."
                    maxLength={6}
                  />
                </div>
              </div>*/}
                            <div id="PhoneNumber" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_PhoneNumber"
                              >
                                Phone number
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="tel"
                                  className="text fieldLarge"
                                  id="Form_Customer_PhoneNumber"
                                  name="PhoneNumber"
                                  defaultValue=""
                                  autoComplete="on"
                                  required="required"
                                  placeholder="Mobile/Landline"
                                  title="Please supply a number we can contact you on during the period of travel."
                                />
                              </div>
                            </div>
                            <div id="Email" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_Email"
                              >
                                Email
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="email"
                                  className="text Email fieldLarge"
                                  id="Form_Customer_Email"
                                  name="Email"
                                  defaultValue=""
                                  autoComplete="on"
                                  required="required"
                                  title="Your email address will be used to send your confirmation and for you to manage your booking."
                                />
                              </div>
                            </div>
                            <div id="Email2" className="field  first last">
                              <label
                                className="left"
                                htmlFor="Form_Customer_Email2"
                              >
                                Confirm Email
                              </label>
                              <div className="middleColumn">
                                <input
                                  type="email"
                                  className="text Email fieldLarge"
                                  id="Form_Customer_Email2"
                                  name="Email2"
                                  defaultValue=""
                                  autoComplete="on"
                                  required="required"
                                  title="Please enter the same email address again to ensure we store the correct value."
                                />
                              </div>
                            </div>
                            <div className="clear">{/* */}</div>
                          </fieldset>
                          <div className="Actions">
                            <input
                              type="button"
                              name="action_pay"
                              defaultValue="Continue"
                              className="action"
                              id="Form_Customer_action_pay"
                              onClick={this.submitForm}
                            />
                          </div>
                        </form>
                      ) : (
                        <div className="ViewForm">
                          <label> Name </label>
                          <span>
                            {" "}
                            {this.state?.passengers[0]?.firstName +
                              this.state?.passengers[0]?.lastName}{" "}
                          </span>
                          <br />
                          <label> Contact </label>
                          <span>
                            {" "}
                            {this.state?.passengers[0]?.firstName +
                              this.state?.passengers[0]?.lastName}{" "}
                          </span>
                          <br />
                          <label> Phone </label>
                          <span>
                            {" "}
                            {this.state?.passengers[0]?.phoneNumber}{" "}
                          </span>
                          <br />
                          <label> Email </label>
                          <span> {this.state?.passengers[0]?.email}</span>
                          <br />
                          <div className="Actions">
                            <a
                              className="Action Edit"
                              onClick={this.editPassenger}
                            >
                              Edit
                            </a>
                            <Link
                              className="Submit"
                              to="/checkout"
                              target="_top"
                            >
                              Continue <span className="right" />
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    )
  }
}

export default PersonalDetails
